$white: var(--white);
$white-rgb: var(--white-rgb);
$black: var(--black);
$black-rgb: var(--black-rgb);
$primary: var(--primary);
$main: var(--main);
$primary-rgb: var(--primary-rgb);
$primary-darken-10: var(--primary-darken-10);
$primary-darken-5: var(--primary-darken-5);
$success: var(--success);
$success-lighten: var(--success-lighten);
$success-darken: var(--success-darken);
$background: var(--background);
$dark-bacground: var(--dark-bacground);
$warn: var(--warn);
$error: var(--error);
$error-darken: var(--error-darken);
$blue: var(--blue);
$pink: var(--pink);
$gray-light-1: var(--gray-light-1);
$gray-light-3: var(--gray-light-3);
$gray-light-2: var(--gray-light-2);
$gray-dark-1: var(--gray-dark-1);
$gray-dark-3: var(--gray-dark-3);
$gray-dark-2: var(--gray-dark-2);
$blue-gray: var(--blue-gray);
$table-font-color: var(--table-font-color);
$text-on-primary: var(--text-on-primary);
$title-color: var(--title-color);
$table-buttons-background: var(--table-buttons-background);
