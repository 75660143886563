@use '@angular/material' as mat;

@import './colors';
@import './sizes';
@import '~mapbox-gl/dist/mapbox-gl.css';

@include mat.core();
@include mat.all-component-densities(-2);

* {
  box-sizing: border-box;
}

// #region material overrides

html,
body {
  height: 100%;
}

.cdk-overlay-container {
  z-index: 9999;
}

.mat-mdc-form-field-icon-prefix .mat-icon.color-picked {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 16px;
    top: 2px;
    border: 1px solid $black;
    border-radius: 100px;
    width: 17px;
    height: 17px;
  }
}

.mat-form-field-disabled .mat-mdc-form-field-icon-prefix .mat-icon {
  opacity: 0.5;
}

td {
  .mdc-icon-button {
    background-color: $main !important;
    color: $text-on-primary;

    @media screen and (max-width: $xsMax) {
      &:not(.success):not(.warning) {
        background-color: $white !important;
        color: $main !important;
        box-shadow: none;
      }
    }

    &.success {
      background-color: $success !important;
    }

    &.warning {
      background-color: $error !important;
    }
  }
}

.mat-toolbar .mat-button-disabled {
  background: none !important;
}
.mat-mdc-paginator {
  border: 1px solid $gray-light-3;
  border-top: none;
  background: $white;
  color: $table-font-color;
}

/* Dark theme focus styling */
.dark-theme
  .mdc-text-field--outlined:not(
    .mdc-text-field--disabled
  ).mdc-text-field--focused
  .mdc-floating-label {
  color: $black;
}

.mat-sort-header-arrow {
  color: $text-on-primary !important;
}
.mat-divider {
  margin: 20px 0 !important;
}
/* Snackbar success and error styling */
.mat-mdc-snack-bar-container {
  &.success {
    .mat-mdc-snackbar-surface {
      background-color: $success;
    }
  }

  &.error {
    .mat-mdc-snackbar-surface {
      background-color: $error;
    }
  }
}

.mat-vertical-content-container {
  padding: 30px 0;
}

.mat-step-icon {
  background-color: $primary !important;

  &.mat-step-icon-state-done {
    background-color: $success !important;
  }

  /* Dialog adjustments */
  .mat-dialog-container {
    max-height: 100vh !important;

    @media screen and (max-width: $xsMax) {
      max-height: calc(100vh - 100px) !important;
    }
  }

  .mat-mdc-dialog-surface {
    max-height: calc(100vh - 160px) !important;
    min-height: 50px;

    @media screen and (max-width: $xsMax) {
      max-height: calc(100vh - 260px) !important;
    }
  }
}

.mat-mdc-button-disabled {
  opacity: 0.5;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
  > .mat-mdc-tab-header
  .mat-mdc-tab {
  flex-grow: 0 !important;
  min-width: 160px;

  @media screen and (max-width: $smMax) {
    min-width: 72px;
  }
}

.mat-button-toggle .mat-pseudo-checkbox {
  display: none;
}

.mdc-text-field--outlined .mat-mdc-form-field-infix,
.mdc-text-field--no-label .mat-mdc-form-field-infix {
  width: auto;
}

.mat-mdc-option {
  .mat-pseudo-checkbox-minimal {
    display: none;
  }
  .mdc-list-item__primary-text {
    padding: 5px 0;
  }
  @media screen and (min-width: $mdMin) {
    .mdc-list-item__primary-text {
      white-space: nowrap !important;
      padding: 0;
    }
  }
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-item {
    &[disabled] {
      opacity: 0.5;
    }
    &.success {
      color: var(--success);
    }

    &.warning {
      color: var(--error);
    }

    &.error {
      color: var(--error);
    }

    &.primary {
      color: var(--primary);
    }
  }
}

.mat-mdc-dialog-container .mat-mdc-dialog-title + .mat-mdc-dialog-content {
  padding-top: 5px !important;
}

.mat-datepicker-content-container {
  background: var(--white);
  box-shadow: var(--mat-select-container-elevation-shadow);
  border-radius: 4px;

  .mat-calendar-body-selected {
    background: var(--primary);
    color: var(--text-on-primary);
  }
}

.mat-mdc-snack-bar-container {
  .mdc-snackbar__label {
    text-align: center;
  }
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    min-width: 0;
  }
}

.mdc-floating-label--required:not(
    .mdc-floating-label--hide-required-marker
  )::after {
  color: $error;
  margin-left: 3px !important;
}

.mdc-button__label {
  min-width: 100%;
}

.mat-mdc-option .mdc-list-item__primary-text {
  min-width: 100%;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  z-index: 1;
}
.mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
  z-index: 0;
}
// #endregion material overrides

// #region global styles
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: $gray-light-1;

  &.fullscreen {
    overflow: hidden;
  }
}

button {
  touch-action: manipulation;
}

/* Autofill customizations */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px $white inset;
  font-size: 16px;
}

/* Placeholder styling */
input::placeholder {
  color: rgba($black-rgb, 0.7) !important;
}

/* Spin button removal for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Spin button removal for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Utility classes */
.hidden {
  display: none !important;
}

.capitalized {
  text-transform: capitalize;
}

.all-caps {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.primary-color {
  color: $primary;
}

.btn-block {
  width: 100%;
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

/* XS screen size adjustments */
@media screen and (max-width: $xsMax) {
  .hidden-xs {
    display: none !important;
  }

  input,
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}

.square {
  position: relative;

  &::after {
    content: '2';
    position: absolute;
    font-size: 9px;
    top: -5px;
  }
}
.cube {
  position: relative;

  &::after {
    content: '3';
    position: absolute;
    font-size: 9px;
    top: -5px;
  }
}

// #endregion global styles

// #region portal styles
.captain-app,
.partner-app,
.charity-app,
.tax-receipt-app,
.zendesk-app {
  /* General resets and spacing */
  .margin-0 {
    margin: 0 !important;
  }

  hr {
    opacity: 0.5;
    margin-bottom: 30px;
  }

  .preview-item {
    margin-bottom: 30px;

    label {
      font-weight: 700;
      color: $primary;
      margin-bottom: 6px;
      display: block;
    }

    h1 {
      color: $blue-gray;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 2px;
    }
  }

  .static-table {
    border: 1px solid $gray-light-2;
    border-collapse: collapse;

    thead {
      tr {
        background-color: $gray-light-2;
        border-bottom: 1px solid $gray-light-2;
        height: 36px;

        th {
          text-align: left;
          padding: 0 5px;
          font-weight: 400;
          color: $gray-dark-1;
        }
      }
    }

    tbody {
      tr {
        height: 30px;
        border-bottom: 1px solid $gray-light-3;

        td {
          padding: 0 5px;
          color: $gray-dark-1;
        }

        &.total {
          background-color: $gray-light-2;
          height: 36px;

          td {
            font-weight: 700;
          }
        }
      }
    }
  }

  .captain-app {
    .page-content {
      padding-bottom: 20px;
    }
  }

  .page-content {
    padding-bottom: 90px;

    .page-content {
      padding-bottom: 0;
    }
  }

  /* Button customizations */
  .primary-btn {
    border: 1px solid $primary !important;
    padding: 2px 30px !important;
    color: $primary-darken-10;
    background-color: $white;
    font-weight: 300;
    box-shadow: none !important;

    .mat-button-wrapper {
      white-space: break-spaces;
    }

    &.filled-btn {
      background-color: $main;
      color: $text-on-primary;
    }

    @media screen and (max-width: $smMax) {
      padding: 0 10px !important;
    }

    &[disabled] {
      opacity: 0.4;
    }
  }

  .primary-btn,
  .mat-raised-button,
  .mat-button,
  .mat-stroked-button,
  .mat-flat-button {
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
      box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 0.15s ease !important;

    &:not(:disabled):hover {
      transform: scale(1.05);
    }
  }

  /* Dark theme adjustments */
  .dark-theme {
    .primary-btn {
      color: $table-font-color;
    }
  }

  /* Warn button customizations */
  .warn-btn {
    border: 1px solid $error !important;
    height: 40px;
    padding: 0 30px !important;
    color: $error-darken;
    font-weight: 300;
    box-shadow: none !important;

    &.filled-btn {
      border: 1px solid $error-darken !important;
      background-color: $error;
      color: $text-on-primary;
    }
  }

  /* Filters wrapper */
  .filters-wrapper {
    margin-bottom: 20px;
    border: 1px solid $gray-light-3;
    padding: 20px 50px;
    background-color: $white;
    font-size: 12px;

    form {
      position: static !important;
    }

    .mat-mdc-form-field-subscript-wrapper,
    .mat-mdc-form-field-bottom-align::before {
      display: none;
    }
  }

  /* Status badge styling */
  span.status-badge {
    padding: 5px 10px;
    color: #ffffff;
    border-radius: 14px;
    background: $success;
    text-transform: capitalize;
    font-weight: 500;

    &.unassigned,
    &.declined,
    &.error {
      background: $error;
    }

    &.assigned,
    &.submitted {
      background: $warn;
      color: #212529;

      &.charity-assigned {
        background: $success;
        color: $text-on-primary;
      }
    }

    &.canceled {
      background: #0261ad;
    }
  }

  /* Has filters flag */
  .has-filters-flag {
    color: $primary;
    font-size: 12px;
    margin-left: 2px;
  }

  @media print {
    .logistics-report {
      .table-container,
      .card-wrapper {
        height: auto !important;
      }
    }
  }

  /* Table container */
  .table-container {
    overflow: overlay;
    border: 1px solid $gray-light-3;
    min-height: 300px;
    background-color: $gray-light-1;

    thead {
      th {
        padding: 4px 8px;
        font-size: 14px;
      }
    }

    tbody {
      tr:not(.expandable-detail-row) {
        height: 45px;

        &:not(.expandable-detail-row):hover {
          background-color: rgba(var(--primary-rgb), 0.08);
        }

        td:not(.action-link) {
          padding: 4px 8px;
          &:first-of-type {
            padding-left: 24px;
          }
        }
      }
    }
  }

  /* Mat-tab-body customization */
  .card-wrapper .mat-tab-body-wrapper {
    &,
    .mat-tab-body.mat-tab-body-active {
      &,
      .mat-tab-body-content {
        overflow: visible !important;
        overflow-y: visible !important;
        overflow-x: visible !important;
      }
    }
  }

  /* Z-index fix */
  .pac-container {
    z-index: 99999;
  }

  /* Error and success text */
  .error-text {
    color: $error;
  }

  .success-text {
    color: $success;
  }
}
// #endregion portal styles

//#region Mapbox
.mapboxgl-ctrl-geocoder {
  background: transparent;
}

.mapboxgl-ctrl-geocoder--input {
  background: rgba($white-rgb, 0.5);
  color: $black;

  &:focus,
  &:hover {
    background: $white;
  }
}
.mapboxgl-ctrl-geocoder--button {
  background-color: transparent;
  .mapboxgl-ctrl-geocoder--icon-close {
    fill: $black;
  }
}

.mapboxgl-ctrl-group {
  background: $white;
}

.dark-theme {
  .mapboxgl-ctrl-group button {
    span {
      filter: invert(0.5);
    }
  }
}
.ngx-mat-timepicker form {
  justify-content: center;
  display: flex;
}
//#endregion Mapbox

//#region Ladda
.ladda-button {
  font-size: 14px;

  &:not(.filled-btn):hover {
    background-color: var(--white);
    color: var(--primary);
  }
  &.filled-btn:hover {
    color: var(--white);
    background-color: var(--primary);
  }

  &[data-loading] {
    background: rgb(var(--primary-rgb), 0.3) !important;
  }
}
//#endregion Ladda

// #region underlined link
a.underlined {
  text-decoration: none;
  color: var(--primary);
  position: relative;
}
a.underlined:after {
  bottom: -2px;
  content: '';
  display: block;
  height: 1.5px;
  left: 0;
  position: absolute;
  background: var(--primary);
  opacity: 0.5;
  transition: width 0.3s ease 0s, opacity 0.3s ease 0s;
  width: 0;
}

a.underlined:hover:after {
  width: 100%;
  opacity: 0.9;
}
// #endregion underlined link

.learn-more-discount {
  .icon {
    width: 48px;
    height: 48px;
    background: #fff1c2;
    border: 1px solid #bf6a02;
    border-radius: 16px;

    .mat-icon {
      color: #bf6a02;
    }
  }

  label {
    color: #bf6a02;
    font-size: 14px;
    font-weight: bold;
  }

  a {
    font-size: 14;
    font-weight: bold;
    color: #0261ad;
    text-decoration: underline;
    cursor: pointer;
  }

  &.charity {
    h1 {
      margin-bottom: 0;
      color: var(--blue-gray);
    }
  }
}
